import { formatUSDWithCents, formatUSDWithoutCents } from "../Utils/Currency";

export type AdvisingPackage =
  { id: number
  , name: string
  , description?: string
  , numMinutes: number
  , totalPriceCents: number
  , refundPeriodLengthDays: number
  , activeForSale: boolean
  }

export function formatPackageHourlyRate(pkg: AdvisingPackage): string {
  const centsPerHour = pkg.totalPriceCents / (pkg.numMinutes / 60);
  return formatUSDWithoutCents(centsPerHour);
}

export type AdvisingSession =
  { id: number
  , advisorId: number
  , name: string
  , description?: string
  , numMinutes: number
  , amountCents: number
  , activeForSale: boolean
  }

// TODO: Incomplete, finish to_client_json method in Advising::Meeting and update this
export type AdvisingMeeting =
  { id: number
  , status: MeetingStatus
  }

export enum MeetingStatus
  { ACTIVE = 'active'
  , COMPLETED = 'completed'
  , RESCHEDULED = 'rescheduled'
  , CANCELLED = 'cancelled'
  , CANCELLED_BY_ADVISOR = 'cancelled-by-advisor'
  }
