import * as React from 'react';
import { useState } from 'react';
import * as API from '../Utils/API.js';
import { htmlIf } from '../Utils/HTML';
import { Advisor, InquiryMode } from '../Types/Advisor';
import { DismissibleModal } from '../Components/Modal';
import * as EmailValidator from 'email-validator';

type Props =
  { advisor: Advisor
  , isAnonymous: boolean
  , btnClass: string
  , btnContent: React.ReactElement
  }

const messagesPath = '/messages'

const InquiryModalButton = (props: Props) => {
  const [showInquiryModal, setShowInquiryModal] = useState(false);

  const [showErrors, setShowErrors] = useState(false);

  const [inquiryMessage, setInquiryMessage] = useState('');
  const [anonFirstName, setAnonFirstName] = useState('');
  const [anonLastName, setAnonLastName] = useState('');
  const [anonEmail, setAnonEmail] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  function submitInquiry() {
    setIsSubmitting(true);

    const postBody = {
      advisorId: props.advisor.id,
      message: inquiryMessage,
      anonEmail: anonEmail,
      anonFirstName: anonFirstName,
      anonLastName: anonLastName
    }

    API.post("consultants_submit_consult_inquiry_path", postBody).then(function (result) {
      console.log(result);
      if (result['error']) {
        if (result['message']) {
          setErrorMessage(result['message'])
        } else {
          setErrorMessage("Something went wrong. Our team has been notified and will investigate the issue.")
        }
      } else {
        window.location.href = messagesPath
        setShowInquiryModal(false);
      }
      setIsSubmitting(false);
    })
  }

  function handleSubmitClicked() {
    if (inquiryMessage.length > 10) {
      if (props.isAnonymous) {
        if (EmailValidator.validate(anonEmail) && anonFirstName.length > 0 && anonLastName.length > 0) {
          submitInquiry();
        } else {
          setShowErrors(true);
        }
      } else {
        submitInquiry();
      }
    } else {
      setShowErrors(true);
    }
  }

  const ViewAnonymousDetailEntry = () => (
    <>
      <div className="row mt-2 text-start">
        <div className="col-md-6">
          <h6 className="fw-normal mb-0">First name*</h6>
          <input
            className="form-control mt-1 t--anon-first-name"
            placeholder="Enter first name…"
            value={anonFirstName}
            onChange={(event) => setAnonFirstName(event.target.value)}
          />
          {htmlIf(showErrors && !anonFirstName.length,
            <div className="fs-sm mt-1 text-danger fw-bold">Please enter your first name.</div>
          )}
        </div>
        <div className="col-md-6 mt-2 mt-md-0">
          <h6 className="fw-normal mb-0">Last name*</h6>
          <input
            className="form-control mt-1 t--anon-last-name"
            placeholder="Enter last name…"
            value={anonLastName}
            onChange={(event) => setAnonLastName(event.target.value)}
          />
          {htmlIf(showErrors && !anonLastName.length,
            <div className="fs-sm mt-1 text-danger fw-bold">Please enter your last name.</div>
          )}
        </div>
        <div className="col mt-2">
          <h6 className="fw-normal mb-0">Email address*</h6>
          <input
            className="form-control mt-1 t--anon-email"
            placeholder="Enter email…"
            value={anonEmail}
            onChange={(event) => setAnonEmail(event.target.value)}
          />
          {htmlIf(showErrors && !EmailValidator.validate(anonEmail),
            <div className="fs-sm mt-1 text-danger fw-bold">Please enter a valid email address.</div>
          )}
        </div>
      </div>
    </>
  )

  const ViewInquiryModal = () => {
    const headerText =
      props.advisor.inquiryMode === InquiryMode.FREE_CONSULTATION
      ? `Request a free consultation with ${props.advisor.preferredName}`
      : props.advisor.inquiryMode === InquiryMode.MORE_INFORMATION
        ? `Request more information from ${props.advisor.preferredName}`
        : ""

    return (
      <DismissibleModal
        dialogClass='modal-lg'
        title={null}
        body={
          <div>
            <div className="text-center text-dark">
              <div className="avatar avatar-xxl mb-3">
                <img className="avatar-img rounded-circle border-gray-200 border border-4" src={props.advisor.imageUrl}/>
              </div>
              <h4 className="">{headerText}</h4>
            </div>
            {htmlIf(props.isAnonymous,
              <div className="mt-4 mb-3 pb-3 border-bottom">
                {ViewAnonymousDetailEntry()}
              </div>
            )}
            <div className="mt-3 text-start">{`How would you like to work with this advisor? What services are you interested in?`}</div>
            <textarea className="form-control mt-1"
              placeholder="Share some details to start the conversation…"
              rows={4}
              value={inquiryMessage}
              onChange={(event) => setInquiryMessage(event.target.value)}
            />
            {htmlIf(showErrors && !(inquiryMessage.length > 10),
              <div className="fs-sm mt-1 text-danger fw-bold text-start">Please share more details.</div>
            )}
            <div className="d-flex align-items-center justify-content-center mt-3">
              <button className="btn btn-primary w-100 w-sm-auto px-sm-6"
                onClick={handleSubmitClicked}
                disabled={isSubmitting}
              >
                Send
              </button>
            </div>
            {htmlIf(errorMessage !== null,
              <div className="mt-2 text-danger fw-bold">{errorMessage}</div>
            )}
          </div>
        }
        onDismiss={() => setShowInquiryModal(false)}
      />
    )
  }

  return (
    <>
      {htmlIf(showInquiryModal, ViewInquiryModal())}
      <button className={props.btnClass} onClick={() => setShowInquiryModal(true)}>
        {props.btnContent}
      </button>
    </>
  )
}

export default InquiryModalButton;
