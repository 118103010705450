import { CustomerRating } from "./CustomerRating"

export type EssayPackage =
  { id: number
  , name: string
  , description?: string
  , basePriceCents: number
  , pricePerWordCents: number
  , activeForSale: boolean
  }

export enum ReviewStatus
  { AWAITING_PAYMENT = 'awaiting-payment'
  , IN_PROGRESS = 'in-progress'
  , COMPLETED = 'completed'
  , REFUNDED = 'refunded'
  }

export type EssayReview =
  { id: number
  , amountCents: number
  , status: ReviewStatus
  , packageName: string
  , guaranteedReturnTimeHours: number
  , advisorId: number
  , advisorName: string
  , advisorImageUrl: string
  , customerUserId: number
  , customerName: string
  , customerRating?: CustomerRating
  , essayPrompt: string
  , essayText: string
  , essayWordLimit: string
  , essayFeedbackRequest: string
  , essayColleges: string[]
  , advisorFeedback: string
  , wordCount: number
  , essayDocxUrl: string
  , createdAt: string
  , paidAt: string
  , completedAt: string
  , refundedAt: string
  , readAt: string
  }

export type EssayReturnTimeTier =
  { id: number
  , returnTimeHours: number
  , additionalChargeCents: number
  , activeForSale: boolean
  }
