import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as API from './Utils/API.js';

const HelloWorld = (props) => {
  const [name, setName] = useState(props.name);

  function testPost(value) {
    API.post("admin_test_path", {name: value, time: 401}).then(function (result) {
      console.log(result);
      setName(result['name']);
    })
  };

  return (
    <div className="container">
      <h3 className="text-primary display-4">Hello, {name}!</h3>
      <hr />
      <form>
        <label className="text-success mb-2" htmlFor="name">
          Say hello to:
        </label>
        <input className="form-control" id="name" type="text" value={name} onChange={(e) => testPost(e.target.value)} />
      </form>
      <button className="btn btn-primary mt-3" onClick={() => setName("")}>Clear!</button>
    </div>
  );
};

HelloWorld.propTypes = {
  name: PropTypes.string.isRequired, // this is passed from the Rails view
};

export default HelloWorld;
