import * as React from 'react';
import { htmlIf, maybeHtml } from '../Utils/HTML';
import { Advisor, advisorProfilePath } from '../Types/Advisor';

type Props =
  { advisor: Advisor
  , targetBlank?: boolean
  }

const ConsultantCard = (props: Props) => {
  return (
    <>
      <a className={`text-decoration-none t--advisor-id-${props.advisor.id}`}
        href={advisorProfilePath(props.advisor.id)}
        target={props.targetBlank ? '_blank' : null}
      >
        <div className="card h-100 rounded-1 border-0 shadow">
          <div className="card-body p-3 d-flex flex-column align-items-stretch">
            <div className="pb-1">
              <div className="avatar avatar-xxl">
                <img className="avatar-img rounded-circle" src={props.advisor.imageUrl} loading='lazy'/>
              </div>
            </div>
            <div className="h5 mt-1 mb-1">{props.advisor.preferredName}</div>
            {maybeHtml(props.advisor.location, (location) => (
              <div className="d-flex align-items-center py-1">
                <i className="ai-map-pin me-1 fs-xl" />
                {location}
              </div>
            ))}
            {maybeHtml(props.advisor.shortEducation, (shortEdu) => (
              <div className="d-flex align-items-top py-1">
                <i className="ai-book me-1 fs-xl" />
                <div className="fs-sm">{shortEdu}</div>
              </div>
            ))}
            <div className="mt-auto bg-gray-200 rounded-3 p-2">
              <div className="row">
                {maybeHtml(props.advisor.yearsOfExperience, (experience) => (
                  <div className="col-6">
                    <div className="fs-sm fw-semibold">Experience</div>
                    <div className="fs-sm">{experience} years</div>
                  </div>
                ))}
                <div className="col-6">
                  <div className="fs-sm fw-semibold">Rating</div>
                  {htmlIf((props.advisor.averageRating !== null && props.advisor.numRatings > 1),
                    <div className="d-flex fs-sm">
                      {props.advisor.averageRating?.toFixed(1)} ({props.advisor.numRatings} reviews)
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </>
  )
}

export default ConsultantCard;
