import * as React from 'react';
import { useState, useEffect } from 'react';
import * as API from '../Utils/API.js';
import { College, collegeToReactSelectItem, reactSelectItemToCollege } from '../Types/College';
import { Advisor } from '../Types/Advisor';
import { EssayPackage, EssayReturnTimeTier } from '../Types/Essays';
import { htmlIf, formatMultiParagraphString, maybeHtml } from '../Utils/HTML';
import { formatUSDWithCents, formatUSDWithoutCents } from '../Utils/Currency';
import Select from 'react-select';
import * as EmailValidator from 'email-validator';

type Props =
  { advisor: Advisor
  , essayPackages: EssayPackage[]
  , returnTimeTiers: EssayReturnTimeTier[]
  , allColleges: College[]
  , isAnonymous: boolean
  }

enum Step
  { SelectPackage
  , EnterEssay
  , SubmitEssay
  }

type ReactSelectOption =
  { value: any
  , label: string
  }

function wordCount(inputString: string) {
  return inputString.replace(/\r\n|\n/g, ' ').split(' ').filter(word => word).length;
}

const SubmitEssay = (props: Props) => {
  const [currentStep, setCurrentStep] = useState(Step.SelectPackage);
  const [completedSteps, setCompletedSteps] = useState(new Set<Step>());

  const [showErrors, setShowErrors] = useState(false);

  const [selectedPackage, setSelectedPackage] = useState<EssayPackage>(props.essayPackages[0] ?? null);
  const [selectedReturnTimeTier, setSelectedReturnTimeTier] = useState<EssayReturnTimeTier>();

  const [essayPrompt, setEssayPrompt] = useState('');
  const [essayWordLimit, setEssayWordLimit] = useState('');
  const [essayColleges, setEssayColleges] = useState<ReactSelectOption[]>([]);
  const [essayFeedbackRequest, setEssayFeedbackRequest] = useState('');
  const [essayText, setEssayText] = useState('');

  const [anonFirstName, setAnonFirstName] = useState('');
  const [anonLastName, setAnonLastName] = useState('');
  const [anonEmail, setAnonEmail] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });

    setShowErrors(false);
  }, [currentStep])

  function totalPriceCents() {
    return (
      selectedPackage?.basePriceCents +
      (selectedPackage?.pricePerWordCents * wordCount(essayText)) +
      (selectedReturnTimeTier?.additionalChargeCents ?? 0)
    )
  }

  function submitEssay() {
    setIsSubmitting(true);

    const colleges: College[] = essayColleges.map((item) => (reactSelectItemToCollege(item, props.allColleges)));

    const postBody = {
      essayPackageId: selectedPackage.id,
      essayReturnTimeTierId: selectedReturnTimeTier?.id,
      essayPrompt: essayPrompt,
      essayWordLimit: essayWordLimit,
      essayColleges: colleges,
      essayFeedbackRequest: essayFeedbackRequest,
      essayText: essayText,
      anonEmail: anonEmail,
      anonFirstName: anonFirstName,
      anonLastName: anonLastName
    }

    API.post("essays_purchase_essay_review_path", postBody).then(function (result) {
      setIsSubmitting(false);

      console.log(result);
      if (result['error']) {
        if (result['message']) {
          setErrorMessage(result['message'])
        } else {
          setErrorMessage("Something went wrong. Our team has been notified and will investigate the issue.")
        }
      } else {
        window.location.href = result['url']
      }
    })
  }

  const ViewReviewerDetails = () => (
    <div className="p-3 pb-0 rounded-2">
      <div className="fs-lg fw-semibold text-dark">Your reviewer</div>
      <div className="d-flex align-items-center mt-1">
        <div className="avatar avatar-xl">
          <img className="avatar-img rounded-circle" src={props.advisor.imageUrl}/>
        </div>
        <div className="ms-1">
          <div className="fs-md ms-1">{props.advisor.preferredName}</div>
          {htmlIf((props.advisor.averageRating !== null && props.advisor.numRatings > 1),
            <div className="d-flex align-items-center">
              <i className="ai-star me-1 text-primary"/>
              {props.advisor.averageRating?.toFixed(1)}
            </div>
          )}
        </div>
      </div>
    </div>
  )

  const ViewProgressNav = () => {
    const steps = [
      { number: 1, title: 'Select a Review', icon: 'ai-grid', step: Step.SelectPackage },
      { number: 2, title: 'Enter Essay', icon: 'ai-edit-alt', step: Step.EnterEssay },
      { number: 3, title: 'Confirm & Submit', icon: 'ai-check', step: Step.SubmitEssay },
    ]

    function navItemClickHandler(event: React.MouseEvent<HTMLAnchorElement>, step: Step) {
      if (completedSteps.has(step)) {
        event.preventDefault();
        setCurrentStep(step);
      } else {
        return
      }
    }

    return (
      <>
        <ViewReviewerDetails />
        <div className="card-header mt-2">
          {steps.map((stepInfo, index) => (
            <div className={`${currentStep === index ? "bg-white border rounded-2" : ""} ${index > 0 ? "mt-1" : ""}`} key={index}>
              <a className={`px-2 py-1 d-flex align-items-center justify-content-between text-decoration-none
                  ${completedSteps.has(stepInfo.step) ? 'cursor-pointer' : ''}`}
                key={index}
                onClick={(e) => navItemClickHandler(e, stepInfo.step)}
              >
                <div className="d-flex align-items-center">
                  <i className={`${stepInfo.icon} h4 mb-0 me-2 ${currentStep === index ? "text-primary" : completedSteps.has(stepInfo.step) ? "text-gray-900" : "text-gray-600"}`} />
                  <div className={`fs-md ${currentStep === index || completedSteps.has(stepInfo.step) ? "text-dark" : "text-gray-700"} text-nowrap`}>
                    {stepInfo.title}
                  </div>
                </div>
                {htmlIf(stepInfo.number !== 6, // Don't show the checkmark on the Review step
                  <div className="avatar avatar-xs me-2">
                    <div className={`avatar-title mb-0 ${completedSteps.has(stepInfo.step) ? "bg-primary" : "bg-gray-300"} rounded-circle text-white h6`}>
                      <i className="ai-check" />
                    </div>
                  </div>
                )}
              </a>
            </div>
          ))}
        </div>
      </>
    )
  }

  const ViewSelectPackagePage = () => {
    function handleNextClicked() {
      if (selectedPackage) {
        setCurrentStep(Step.EnterEssay);
        setCompletedSteps(completedSteps.add(Step.SelectPackage));
      } else {
        setShowErrors(true);
      }
    }

    return (
      <div className="card">
        <div className="">
          <div className="card-header border-0 py-2">
            <div className="fs-md">Step 1 of 3</div>
            <div className="h2 mb-0 text-dark">Let’s get started</div>
          </div>
          <div className="card-body py-3 border-top border-bottom">
            <h6 className="fw-bold mb-3">What kind of review are you looking for?</h6>
            <div className="row mb-n2">
              {props.essayPackages.map((essayPkg) => (
                <div className="col-md-6 mt-2 mt-md-0 mb-2">
                  <div className={`card h-100 rounded-2 cursor-pointer t--essay-package-${essayPkg.id} ${selectedPackage?.id === essayPkg.id ? " border-primary" : ""}`}
                    onClick={() => setSelectedPackage(essayPkg)}
                  >
                    <div className="card-body p-3">
                      <h5 className={"mb-1 " + (selectedPackage?.id === essayPkg.id ? "text-primary" : "")}>{essayPkg.name}</h5>
                      <div className="fs-sm text-dark">
                        {essayPkg.basePriceCents > 0 && essayPkg.pricePerWordCents > 0 ? (
                          `${formatUSDWithoutCents(essayPkg.basePriceCents)} + ${essayPkg.pricePerWordCents}¢ per word`
                        ) : essayPkg.basePriceCents > 0 ? (
                          formatUSDWithoutCents(essayPkg.basePriceCents)
                        ) : (
                          `${essayPkg.pricePerWordCents}¢ per word`
                        )}
                      </div>
                      <div className="fs-sm">{essayPkg.description}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {htmlIf(showErrors && !selectedPackage,
              <div className="fs-sm mt-1 text-danger fw-bold">Please select a review type.</div>
            )}
            { props.returnTimeTiers.length > 0
            ? <div>
                <h6 className="fw-bold mt-2 mb-1">How quickly would you like your essay reviewed?</h6>
                {props.returnTimeTiers.sort((a, b) => a.returnTimeHours - b.returnTimeHours).map((tier, index) => (
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id={`tier-${tier.id}`}
                      name="returnTime"
                      onChange={(e) => setSelectedReturnTimeTier(tier)}
                      checked={selectedReturnTimeTier === tier}
                    />
                    <label className="form-check-label align-items-center" htmlFor={`tier-${tier.id}`}>
                      <strong>Expedited delivery:</strong> {tier.returnTimeHours} hours (+{formatUSDWithCents(tier.additionalChargeCents)})
                      {htmlIf(index === 0,
                        <span className="badge bg-faded-success text-success ms-1 align-middle">
                          <i className="ai-star me-1"/>
                          Best choice
                        </span>
                      )}
                    </label>
                  </div>
                ))}
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="standard-return-time"
                    name="returnTime"
                    onChange={(e) => setSelectedReturnTimeTier(null)}
                    checked={selectedReturnTimeTier === null || selectedReturnTimeTier === undefined}
                    defaultChecked
                  />
                  <label className="form-check-label" htmlFor="standard-return-time">
                    <strong>Standard delivery:</strong> Up to 48 hours
                  </label>
                </div>
                {htmlIf(selectedReturnTimeTier !== null && selectedReturnTimeTier !== undefined,
                  <div className="mt-2 bg-post-it-green p-3 rounded-4">
                    <div className="fw-semibold">Guaranteed return time</div>
                    <div className="fs-sm">
                      By selecting an expedited delivery option, you’re guaranteeing that your essay will be returned in 
                      <strong>{selectedReturnTimeTier?.returnTimeHours} hours</strong>
                       (or sooner). If your review takes even a minute longer, 
                      <a href="mailto:support@sundialessayreview.com">email our support team</a>
                       and we’ll be happy to refund you the additional charge.
                    </div>
                  </div>
                )}
              </div>
            : <div className="mt-2 fw-semibold">Standard return time: 48 hours</div>
            }
          </div>
          <div className="card-footer d-flex border-0">
            <button className="btn btn-primary px-6 ms-2 t--step-1-next t--select-package-next"
              onClick={handleNextClicked}
              disabled={!selectedPackage}
            >
                Next
            </button>
          </div>
        </div>
      </div>
    )
  }

  const ViewEnterEssayPage = () => {
    function handleNextClicked() {
      if (essayPrompt.length > 0
        && essayWordLimit.length > 0
        && essayFeedbackRequest.length > 0
        && essayText.length > 0
      ) {
        setCurrentStep(Step.SubmitEssay);
        setCompletedSteps(completedSteps.add(Step.EnterEssay));
      } else {
        setShowErrors(true);
      }
    }

    return (
      <div className="card">
        <div className="">
          <div className="card-header border-0 py-2">
            <div className="fs-md">Step 2 of 3</div>
            <div className="h2 mb-0 text-dark">Add details on your essay</div>
          </div>
          <div className="card-body py-3 border-top border-bottom">
            {/* ESSAY PROMPT */}
            <h6 className="fw-bold mb-0">Essay prompt*</h6>
            <textarea
              className="form-control mt-1 t--essay-prompt"
              rows={3}
              placeholder="Enter the prompt for the essay…"
              value={essayPrompt}
              onChange={(event) => setEssayPrompt(event.target.value)}
            />
            {htmlIf(showErrors && !essayPrompt.length,
              <div className="fs-sm mt-1 text-danger fw-bold">Please enter the essay prompt.</div>
            )}
            {/* WORD LIMIT */}
            <h6 className="fw-bold mb-0 mt-3">Word limit*</h6>
            <input
              className="form-control mt-1 t--essay-word-limit"
              placeholder="Enter word limit…"
              value={essayWordLimit}
              onChange={(event) => setEssayWordLimit(event.target.value)}
            />
            {htmlIf(showErrors && !essayWordLimit.length,
              <div className="fs-sm mt-1 text-danger fw-bold">Please enter a word limit.</div>
            )}
            {/* ESSAY COLLEGES */}
            <h6 className="fw-bold mb-0 mt-3">Which school is this essay for?</h6>
            {/* <div className="fs-sm text-muted mb-1">(Select all that apply)</div> */}
            <Select
              className="rounded mt-1"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              isMulti={true}
              options={props.allColleges.map((college) => (collegeToReactSelectItem(college)))}
              onChange={(newColleges: ReactSelectOption[]) => setEssayColleges(newColleges)}
              value={essayColleges}
              placeholder='Select all that apply…'
            />
            <div className="fs-xs text-muted mt-1">If your school does not appear in the dropdown, leave it blank and add a note in the next box.</div>
            {/* ESSAY FEEDBACK REQUEST */}
            <h6 className="fw-bold mb-0 mt-3">What are you looking for feedback on?*</h6>
            <textarea
              className="form-control mt-1 t--essay-feedback-request"
              rows={3}
              placeholder="Help your reviewer to understand what you’re looking for…"
              value={essayFeedbackRequest}
              onChange={(event) => setEssayFeedbackRequest(event.target.value)}
            />
            {htmlIf(showErrors && !essayFeedbackRequest.length,
              <div className="fs-sm mt-1 text-danger fw-bold">Please share some details here!</div>
            )}
            {/* ESSAY TEXT */}
            <h6 className="fw-bold mb-0 mt-3">Essay draft*</h6>
            <textarea
              className="form-control mt-1 t--essay-text"
              rows={8}
              placeholder="Copy and paste your essay into the box. If you’re purchasing a Topic Check, you can enter your outline or topic ideas here!"
              value={essayText}
              onChange={(event) => setEssayText(event.target.value)}
            />
            <div className="fs-sm mt-1">Word count: {wordCount(essayText)}</div>
            {htmlIf(showErrors && !essayText.length,
              <div className="fs-sm mt-1 text-danger fw-bold">You didn't enter your essay!</div>
            )}
          </div>
          <div className="card-footer d-flex border-0">
            <button onClick={() => setCurrentStep(Step.SelectPackage)} className="btn btn-outline-secondary">
              Back
            </button>
            <button className="btn btn-primary px-6 ms-2 t--step-2-next t--enter-essay-next"
              onClick={handleNextClicked}
            >
                Next
            </button>
          </div>
        </div>
      </div>
    )
  }

  const ViewAnonymousDetailEntry = () => (
    <>
      <div className="fs-md fw-semibold">Before you submit your essay, let’s make sure we know how to get it back to you!</div>
      <div className="row mt-2">
        <div className="col-md-6">
          <h6 className="fw-bold mb-0">First name*</h6>
          <input
            className="form-control mt-1 t--anon-first-name"
            placeholder="Enter first name…"
            value={anonFirstName}
            onChange={(event) => setAnonFirstName(event.target.value)}
          />
          {htmlIf(showErrors && !anonFirstName.length,
            <div className="fs-sm mt-1 text-danger fw-bold">Please enter your first name.</div>
          )}
        </div>
        <div className="col-md-6 mt-2 mt-md-0">
          <h6 className="fw-bold mb-0">Last name*</h6>
          <input
            className="form-control mt-1 t--anon-last-name"
            placeholder="Enter last name…"
            value={anonLastName}
            onChange={(event) => setAnonLastName(event.target.value)}
          />
          {htmlIf(showErrors && !anonLastName.length,
            <div className="fs-sm mt-1 text-danger fw-bold">Please enter your last name.</div>
          )}
        </div>
        <div className="col mt-2">
          <h6 className="fw-bold mb-0">Email address*</h6>
          <input
            className="form-control mt-1 t--anon-email"
            placeholder="Enter email…"
            value={anonEmail}
            onChange={(event) => setAnonEmail(event.target.value)}
          />
          {htmlIf(showErrors && !EmailValidator.validate(anonEmail),
            <div className="fs-sm mt-1 text-danger fw-bold">Please enter a valid email address.</div>
          )}
        </div>
      </div>
    </>
  )

  const ViewSubmitEssayPage = () => {
    function handleSubmitEssayClicked() {
      if (props.isAnonymous) {
        if (EmailValidator.validate(anonEmail) && anonFirstName.length > 0 && anonLastName.length > 0) {
          submitEssay();
        } else {
          setShowErrors(true);
        }
      } else {
        submitEssay();
      }
    }

    return (
      <div className="card">
        <div className="">
          <div className="card-header border-0 py-2">
            <div className="fs-md">Step 3 of 3</div>
            <div className="h2 mb-0 text-dark">Submit your essay</div>
          </div>
          <div className="card-body py-3 border-top border-bottom">
              {htmlIf(props.isAnonymous,
                <div className="mb-3 pb-2 border-bottom">
                  {ViewAnonymousDetailEntry()}
                </div>
              )}
              <div className="row">
                <div className="col-md-6">
                  <div className="fs-md fw-semibold text-gray-900">Package</div>
                  <div className="fs-md">{selectedPackage.name}</div>
                </div>
                <div className="col-md-6 mt-2 mt-md-0">
                  <div className="fs-md fw-semibold text-gray-900">Return Time</div>
                  {selectedReturnTimeTier !== null && selectedReturnTimeTier !== undefined
                  ? <div className="fs-md">Expedited: {selectedReturnTimeTier.returnTimeHours} hours</div>
                  : <div className="fs-md">Standard: 48 hours</div>
                  }
                </div>
              </div>
              <div className="mt-2">
                <div className="fs-md fw-semibold text-gray-900">Prompt</div>
                <div className="fs-md">{formatMultiParagraphString(essayPrompt)}</div>
              </div>
              <div className="mt-2">
                <div className="fs-md fw-semibold text-gray-900">Feedback Requested</div>
                <div className="fs-md">{formatMultiParagraphString(essayFeedbackRequest)}</div>
              </div>
              <div className="mt-2">
                <div className="fs-md fw-semibold text-gray-900">Essay Draft (preview)</div>
                <div className="fs-md truncate-5-lines">{formatMultiParagraphString(essayText)}</div>
              </div>
              {/* <hr className="mt-2" /> */}
              <div className="fs-lg fw-bold mt-2 text-dark">Summary</div>
              <div className="mt-2 fs-md">
                {htmlIf(selectedPackage?.basePriceCents > 0,
                  <div className="row">
                    <div className="col">Base Price</div>
                    <div className="col-auto text-end">{formatUSDWithCents(selectedPackage.basePriceCents)}</div>
                  </div>
                )}
                {htmlIf(selectedPackage?.pricePerWordCents > 0,
                  <div className="row">
                    <div className="col">Word Count: {wordCount(essayText)}</div>
                    <div className="col-auto text-end">{formatUSDWithCents(selectedPackage.pricePerWordCents * wordCount(essayText))}</div>
                  </div>
                )}
                {maybeHtml(selectedReturnTimeTier, (tier) => (
                  <div className="row">
                    <div className="col">Guaranteed return time ({tier.returnTimeHours} hours)</div>
                    <div className="col-auto text-end">{formatUSDWithCents(tier.additionalChargeCents)}</div>
                  </div>
                ))}
              </div>
              <hr className="mt-2" />
              <div className="mt-1 mb-2 text-dark">
                <div className="row">
                  <div className="col">Total</div>
                  <div className="col-auto text-end fw-bold">{formatUSDWithCents(totalPriceCents())}</div>
                </div>
              </div>
              <div className="mt-3 fs-sm text-dark">
                Clicking below will take you to Stripe to enter payment information.
              </div>
          </div>
          <div className="card-footer border-0">
            <div className="d-flex">
              <button onClick={() => setCurrentStep(Step.EnterEssay)} className="btn btn-outline-secondary">
                Back
              </button>
              <button className="btn btn-primary px-6 ms-2 t--submit-and-pay t--submit-essay-next"
                onClick={handleSubmitEssayClicked}
                disabled={isSubmitting}
              >
                  Submit & Pay
              </button>
            </div>
            {htmlIf(errorMessage !== null,
              <div className="mt-2 text-danger fw-bold">{errorMessage}</div>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="row">
    <div className="col-lg-4 col-xl-3 d-none d-lg-block">
      <div className="sticky-top">
        <ViewProgressNav />
      </div>
    </div>
    <div className="col-12 col-lg-8 col-xl-9">
      {(() => {
        switch (currentStep) {
          case Step.SelectPackage:
            return ViewSelectPackagePage();
          case Step.EnterEssay:
            return ViewEnterEssayPage();
          case Step.SubmitEssay:
            return ViewSubmitEssayPage();
          default:
            return null;
        }
      })() as React.ReactNode}
    </div>
  </div>
  );
}

export default SubmitEssay;
